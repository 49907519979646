import { useSearchParams } from 'react-router-dom';

const Privacy = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <div>
            <h1>Privacy Policy</h1>
            <p>Last updated: 2024/09/25</p>

            <p>Welcome to <b>QR Scanner & Barcode - AI QR.</b> We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and your rights regarding your data when using our application.</p>

            <h2>1. Information We Collect</h2>
            <p>We collect several different types of information for various purposes to provide and improve our services to you.</p>

            <h3>1.1. Personal Data</h3>
            <p>We do not collect personally identifiable information directly such as your name, email address, or contact details. However, our app may request permission to access certain features of your device, including:</p>
            <ul>
                <li><strong>Camera Access:</strong> To enable the scanning of QR codes and barcodes.</li>
                <li><strong>Photos/Media/Files:</strong> If you choose to upload images from your device for scanning, we will need access to your photos or files.</li>
                <li><strong>Location Information:</strong> We may collect and store your device's approximate location to enhance the accuracy of certain services, although this is only used with your explicit consent.</li>
            </ul>

            <h3>1.2. Usage Data</h3>
            <p>We may also collect information on how the app is accessed and used, which may include the following:</p>
            <ul>
                <li>IP address</li>
                <li>Device type and operating system</li>
                <li>App version</li>
                <li>Date and time of use</li>
                <li>Features accessed and interactions with the app</li>
                <li>Crash reports and diagnostic information</li>
            </ul>

            <h3>1.3. Cookies and Tracking Technologies</h3>
            <p>We do not use cookies in this app. However, some third-party services integrated with our app, such as advertising networks or analytics providers, may use tracking technologies to collect data about your interactions with their content. You have the option to accept or refuse these cookies by configuring your device's settings.</p>

            <h2>2. How We Use Your Information</h2>
            <p>The data we collect is used for the following purposes:</p>
            <ul>
                <li><strong>To provide and maintain our services:</strong> This includes enabling the core features of the app, such as scanning QR codes and barcodes, as well as ensuring the app operates smoothly on your device.</li>
                <li><strong>To improve our services:</strong> We analyze app usage data to better understand how users interact with the app, which helps us improve features and fix potential issues.</li>
                <li><strong>To personalize your experience:</strong> With your consent, we may use location data or other information to offer services tailored to your preferences or specific needs.</li>
                <li><strong>To communicate with you:</strong> We may use your contact information (if provided) to respond to inquiries, provide technical support, or share updates about the app.</li>
                <li><strong>To comply with legal obligations:</strong> In certain situations, we may be required to share your data to comply with legal obligations, resolve disputes, or enforce agreements.</li>
            </ul>

            <h2>3. Sharing and Disclosure of Information</h2>
            <p>We are committed to maintaining your privacy and will not share your personal information with third parties except in the following situations:</p>
            <ul>
                <li><strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our app, such as cloud hosting services, data analytics providers, or advertising partners. These providers are bound by confidentiality agreements and are not permitted to use your information for any other purposes.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your personal information if required to do so by law or if we believe such action is necessary to comply with a legal obligation, protect and defend our rights or property, or protect the personal safety of users or the public.</li>
                <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your personal information may be transferred. We will notify you before your personal data is transferred and becomes subject to a different Privacy Policy.</li>
            </ul>

            <h2>4. Security of Your Information</h2>
            <p>Your privacy is important to us, and we are committed to safeguarding your data. We employ reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

            <h2>5. Third-Party Services</h2>
            <p>Our app may contain links to third-party services, such as external websites or advertisements. If you click on these links, you will be directed to the third-party site. Please note that we are not responsible for the privacy practices of these external sites, and we encourage you to review their privacy policies before providing any personal information.</p>

            <h2>6. Your Data Protection Rights</h2>
            <p>You have the right to access, update, or delete your personal information. If you wish to exercise any of these rights, please contact us at the email address provided below. Additionally, if you are a resident of the European Economic Area (EEA), you have certain data protection rights under the General Data Protection Regulation (GDPR). These rights include:</p>
            <ul>
                <li>The right to access – You have the right to request copies of your personal data.</li>
                <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or incomplete.</li>
                <li>The right to erasure – You have the right to request that we erase your personal data under certain conditions.</li>
                <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data under certain conditions.</li>
                <li>The right to data portability – You have the right to request that we transfer your data to another organization, or directly to you, under certain conditions.</li>
            </ul>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>We reserve the right to update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes by updating the "Last updated" date at the top of this page. We encourage you to review this Privacy Policy periodically for any updates.</p>

            <h2>8. Contact Us</h2>
            <p>If you have any questions or concerns regarding this Privacy Policy or wish to exercise your data protection rights, please contact us using the following methods:</p>
            <ul>
                <li>Email: ldaiautowin@gmail.com</li>
            </ul>
        </div>
    );
}

export default Privacy;
