import { useSearchParams } from 'react-router-dom';

const Terms = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <div>
            <h1>Terms of Service</h1>
            <p>Last updated: 2024/09/25</p>

            <p>Welcome to <b>QR Scanner & Barcode - AI QR!</b> These terms and conditions outline the rules and regulations for using our mobile application. By using this app, you agree to these terms in full. If you disagree with any part of the terms, you must discontinue using our app immediately.</p>

            <h2>1. License to Use the Application</h2>
            <p>Subject to your compliance with these Terms of Service, we grant you a limited, non-exclusive, non-transferable, and revocable license to use our app for personal, non-commercial purposes. You agree not to:</p>
            <ul>
                <li>Modify, distribute, or sell any part of the app without prior permission.</li>
                <li>Reverse engineer or decompile the app's source code.</li>
                <li>Use the app in any way that could damage or interfere with the normal operation of the app.</li>
            </ul>

            <h2>2. User-Generated Content</h2>
            <p>By uploading or submitting any content (such as scanned QR codes or barcodes) to the app, you retain ownership of your content. However, by submitting content, you grant us a worldwide, non-exclusive, royalty-free, perpetual, and irrevocable license to use, distribute, reproduce, modify, adapt, and publicly display your content in connection with the app.</p>

            <h2>3. Acceptable Use</h2>
            <p>Users agree to use the app in compliance with all applicable laws and regulations. You must not use the app to:</p>
            <ul>
                <li>Upload or transmit any harmful, fraudulent, or unlawful content.</li>
                <li>Violate the privacy or rights of other individuals.</li>
                <li>Engage in any activity that could damage, disable, or impair the app or interfere with other users' access and enjoyment of the app.</li>
            </ul>

            <h2>4. Termination</h2>
            <p>We reserve the right to terminate or suspend your access to the app at our sole discretion, without notice, if you violate these Terms of Service or if we believe your use of the app is harmful to others or the app itself. Upon termination, your right to use the app will cease immediately.</p>

            <h2>5. Intellectual Property</h2>
            <p>All content, including but not limited to text, images, logos, and software code in the app, is the property of QR Scanner & Barcode - AI QR and is protected by applicable intellectual property laws. You agree not to copy, distribute, or create derivative works from any part of the app without express written permission.</p>

            <h2>6. Disclaimer of Warranties</h2>
            <p>The app is provided on an "as is" and "as available" basis without warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the app will be error-free, secure, or that it will operate without disruptions.</p>

            <h2>7. Limitation of Liability</h2>
            <p>In no event shall QR Scanner & Barcode - AI QR, its developers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the app, even if we have been advised of the possibility of such damages. Our total liability to you for any claims arising from your use of the app shall not exceed the amount paid by you to us, if any, for accessing the app.</p>

            <h2>8. Modifications to the Terms</h2>
            <p>We reserve the right to modify these Terms of Service at any time. We will notify you of any significant changes by posting the updated terms on this page and updating the "Last updated" date. Your continued use of the app after any such changes constitute your acceptance of the new Terms of Service.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions about these Terms of Service, please contact us at:</p>
            <ul>
                <li>Email: ldaiautowin@gmail.com</li>
            </ul>
        </div>
    );
}

export default Terms;
